
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import Modal from "@/components/shared/layout/Modal.vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {
  Endpoint,
  EndpointsApi,
  Guide,
  GuidesApi
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";

extend("required", required);
extend("url", {
  validate(value: string | null | undefined): boolean {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      );
    }

    return false;
  },
  message: "This value must be a valid URL"
});

@Component({
  components: { Modal, ValidationProvider, ValidationObserver }
})
export default class EndpointDialog extends Vue {
  system = "";
  environment = "";
  url = "";

  @Prop({ default: null })
  endpoint!: Endpoint | null;

  created() {
    if (this.endpoint) {
      this.system = this.endpoint.system || "";
      this.environment = this.endpoint.environment || "";
      this.url = this.endpoint.url || "";
    }
  }

  addEndpoint(validate: () => Promise<any>) {
    validate().then(() => {
      if (this.endpoint) {
        new EndpointsApi(AuthInterceptor.Instance)
          .updateEndpoint({
            endpointGuid: this.endpoint.endpointGuid || "",
            endpoint: {
              endpointGuid: this.endpoint.endpointGuid,
              system: this.system,
              environment: this.environment,
              url: this.url
            }
          })
          .subscribe(value => {
            this.$emit("added");
          });
      } else {
        new EndpointsApi(AuthInterceptor.Instance)
          .createEndpoint({
            endpoint: {
              system: this.system,
              environment: this.environment,
              url: this.url
            }
          })
          .subscribe(value => {
            this.$emit("added");
          });
      }
    });
  }

  deleteEndpoint() {
    if (confirm("Are you sure you want to delete the endpoint?")) {
      new EndpointsApi(AuthInterceptor.Instance)
        .deleteEndpoint({
          endpointGuid: this.endpoint?.endpointGuid || ""
        })
        .subscribe(value => {
          this.$emit("added");
        });
    }
  }

  @Emit()
  added(guide: Guide) {
    //
  }

  @Emit()
  closed(event: any) {
    this.$emit("closed");
  }
}
