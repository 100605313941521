
import { Component, Vue } from "vue-property-decorator";
import {
  EndpointsApi,
  Endpoint,
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import ElementList, {
  ListColumn,
} from "@/components/shared/layout/ElementList.vue";
import EndpointDialog from "@/components/endpoints/EndpointDialog.vue";

@Component({
  components: { EndpointDialog, ElementList },
})
export default class GuideList extends Vue {
  guides: Array<Endpoint> = [];
  search = "";
  newEndpoint = false;
  columns: ListColumn<Endpoint>[] = [
    {
      header: "System",
      key: "system",
      field: "system",
    },
    {
      header: "Environment",
      key: "link",
      field: "environment",
    },
    {
      header: "Url",
      key: "url",
      field: "url",
    },
  ];
  private endpoint: Endpoint | null = null;

  endpointClicked(endpoint: Endpoint) {
    this.endpoint = endpoint;
    this.newEndpoint = true;
  }

  created() {
    this.refresh();
  }

  endpointAdded() {
    this.endpoint = null;
    this.refresh();
  }

  closeModal() {
    this.endpoint = null;
    this.newEndpoint = false;
  }

  filterGuide(item: Endpoint, filter: string) {
    return (
      item.system?.toLowerCase().includes(filter.toLowerCase()) ||
      item.environment?.toLowerCase().includes(filter.toLowerCase()) ||
      item.url?.toLowerCase().includes(filter.toLowerCase())
    );
  }

  private refresh() {
    new EndpointsApi(AuthInterceptor.Instance)
      .getEndpointsList()
      .subscribe((value) => {
        this.guides = value;
      });
    this.newEndpoint = false;
  }
}
